import faunadb from "faunadb"
import * as urljoin from "url-join"

const q = faunadb.query
const { Call } = q

class QueryManager {
  constructor(token) {
    this.client = new faunadb.Client({
      secret: process.env.GATSBY_BOOTSTRAP_KEY,
    })
    this.logout = this.logout.bind(this)
    this.logoutAll = this.logoutAll.bind(this)
    this.user = null
  }

  getUser() {
    return this.user
  }

  login(username, password) {
    username = username.toLowerCase()
    return this.postData(
      urljoin(process.env.GATSBY_BASE_API, ".netlify/functions/login"),
      { username, password }
    )
      .then(res => {
        /*         console.log("QueryManager -> login -> res", res) */
        if (res) {
          this.client = new faunadb.Client({ secret: res.secret })
          this.user = res.user
          return res.user
        }
        return res
      })
      .catch(err => {
        /*         console.log("error calling frontend - login", err) */
        throw err
      })
  }

  register(username, password, alias) {
    username = username.toLowerCase()
    return this.postData(
      urljoin(process.env.GATSBY_BASE_API, ".netlify/functions/register"),
      { username, password, alias }
    )
      .then(res => {
        /*         console.log("Q.register | res", res) */
        if (res) {
          this.client = new faunadb.Client({ secret: res.secret })
          this.user = res.user
          return res.user
        }
        return res
      })
      .catch(err => {
        /*         console.log("Q.register | error calling backend - register", err) */
        throw err
      })
  }

  subscribe(
    isYearlyUDF,
    tierUDF,
    subscriptionIDUDF,
    start_timeUDF,
    plan_idUDF
  ) {
    return this.postData(
      urljoin(
        process.env.GATSBY_BASE_API,
        ".netlify/functions/lambda-subscribe"
      ),
      {
        isYearlyUDF,
        tierUDF,
        subscriptionIDUDF,
        start_timeUDF,
        plan_idUDF,
      }
    )
      .then(res => {
        /*         console.log("QM/subscribe/then/res: ", res) */
        this.client = new faunadb.Client({ secret: res.secret })
        this.user = res && res.user
        return res
      })
      .catch(err => {
        /*         console.log("QM/subscribe/catch/err-error calling frontend: ", err) */
        throw err
      })
  }

  logout() {
    return this.postData(
      urljoin(process.env.GATSBY_BASE_API, ".netlify/functions/logout"),
      {}
    )
      .then(res => {
        this.user = null
        this.client = new faunadb.Client({
          secret: process.env.GATSBY_BOOTSTRAP_KEY,
        })
        /*         console.log("QueryManager -> logout -> this.client", this.client) */
        return res
      })
      .catch(err => {
        /*         console.log("error calling frontend - logout", err) */
      })
  }

  logoutAll() {
    return this.postData(
      urljoin(
        process.env.GATSBY_BASE_API,
        ".netlify/functions/logout?all=true"
      ),
      {}
    )
      .then(res => {
        this.user = null
        this.client = new faunadb.Client({
          secret: process.env.GATSBY_BOOTSTRAP_KEY,
        })
        /*         console.log("QueryManager -> logoutAll -> this.client", this.client) */
        return res
      })
      .catch(err => {
        /*         console.log("error calling frontend - logoutall", err) */
      })
  }

  QM_get_decks_where_alias(
    size,
    after,
    before,
    alias,
    withinDays,
    deckFormat,
    deckColor,
    cardsIncluded,
    cardsExcluded,
    orderByUDF
  ) {
    return this.wrapCallInRetry(() =>
      this.client.query(
        Call(
          q.Function("get_decks_where_alias"),
          size,
          after,
          before,
          alias,
          withinDays,
          deckFormat,
          deckColor,
          cardsIncluded,
          cardsExcluded,
          orderByUDF
        )
      )
    ).catch(err => {
      /*       console.log(
        "catch/querymanager/QM_get_decks_where_alias/error-fetching ",
        err
      ) */
      throw err
    })
  }

  QM_get_decks_all(
    size,
    after,
    before,
    withinDays,
    deckFormat,
    deckColor,
    cardsIncluded,
    cardsExcluded,
    orderByUDF
  ) {
    return this.wrapCallInRetry(() =>
      this.client.query(
        Call(
          q.Function("get_decks_all"),
          size,
          after,
          before,
          withinDays,
          deckFormat,
          deckColor,
          cardsIncluded,
          cardsExcluded,
          orderByUDF
        )
      )
    ).catch(err => {
      /*       console.log("catch/querymanager/QM_get_decks_all/error-fetching ", err) */
      throw err
    })
  }

  QM_get_decks_friends(
    size,
    after,
    before,
    withinDays,
    deckFormat,
    deckColor,
    cardsIncluded,
    cardsExcluded,
    orderByUDF
  ) {
    return this.wrapCallInRetry(() =>
      this.client.query(
        Call(
          q.Function("get_decks_friends"),
          size,
          after,
          before,
          withinDays,
          deckFormat,
          deckColor,
          cardsIncluded,
          cardsExcluded,
          orderByUDF
        )
      )
    ).catch(err => {
      /*       console.log(
        "catch/querymanager/QM_get_decks_friends/error-fetching ",
        err
      ) */
      throw err
    })
  }

  QM_get_deck_where_ref(deckRefUDF) {
    return this.wrapCallInRetry(() =>
      this.client.query(Call(q.Function("get_deck_where_ref"), deckRefUDF))
    ).catch(err => {
      /*       console.log(
        "catch/querymanager/QM_get_deck_where_ref/error-fetching ",
        err
      ) */
      throw err
    })
  }

  QM_get_deck_where_ref_for_builder(deckRefUDF) {
    return this.wrapCallInRetry(() =>
      this.client.query(
        Call(q.Function("get_deck_where_ref_for_builder"), deckRefUDF)
      )
    ).catch(err => {
      /*       console.log(
        "catch/querymanager/QM_get_deck_where_ref_for_builder/error-fetching ",
        err
      ) */
      throw err
    })
  }

  QM_like_deck(deckRefUDF) {
    return this.wrapCallInRetry(() =>
      this.client.query(Call(q.Function("like_deck"), deckRefUDF))
    ).catch(err => {
      /*       console.log("catch/querymanager/QM_likeDeck/error-fetching ", err) */
      throw err
    })
  }

  QM_follow_user(aliasUDF) {
    return this.wrapCallInRetry(() =>
      this.client.query(Call(q.Function("follow_user"), aliasUDF))
    ).catch(err => {
      /*       console.log("catch/querymanager/follow_user/error-fetching ", err) */
      throw err
    })
  }

  QM_get_friends() {
    return this.wrapCallInRetry(() =>
      this.client.query(Call(q.Function("get_friends")))
    ).catch(err => {
      /*       console.log("catch/querymanager/get_friends/error-fetching ", err) */
      throw err
    })
  }

  QM_get_decks_mine(
    size,
    after,
    before,
    withinDays,
    deckFormat,
    deckColor,
    cardsIncluded,
    cardsExcluded,
    orderByUDF
  ) {
    return this.wrapCallInRetry(() =>
      this.client.query(
        Call(
          q.Function("get_decks_mine"),
          size,
          after,
          before,
          withinDays,
          deckFormat,
          deckColor,
          cardsIncluded,
          cardsExcluded,
          orderByUDF
        )
      )
    ).catch(err => {
      /*       console.log("catch/querymanager/QM_get_decks_mine/error-fetching ", err) */
      throw err
    })
  }

  QM_get_decks_favorite(
    size,
    after,
    before,
    withinDays,
    deckFormat,
    deckColor,
    cardsIncluded,
    cardsExcluded,
    orderByUDF
  ) {
    return this.wrapCallInRetry(() =>
      this.client.query(
        Call(
          q.Function("get_decks_favorite"),
          size,
          after,
          before,
          withinDays,
          deckFormat,
          deckColor,
          cardsIncluded,
          cardsExcluded,
          orderByUDF
        )
      )
    ).catch(err => {
      /*       console.log(
        "catch/querymanager/QM_get_decks_favorite/error-fetching ",
        err
      ) */
      throw err
    })
  }

  QM_save_deck(deckUDF) {
    return this.wrapCallInRetry(() =>
      this.client.query(Call(q.Function("save_deck"), deckUDF))
    ).catch(err => {
      /*       console.log("catch/querymanager/save_deck/error-fetching ", err) */
      throw err
    })
  }

  QM_delete_deck(deckUDF) {
    return this.wrapCallInRetry(() =>
      this.client.query(Call(q.Function("delete_deck"), deckUDF))
    ).catch(err => {
      /*       console.log("catch/querymanager/delete_deck/error-fetching ", err) */
      throw err
    })
  }

  QM_get_user_info(aliasUDF) {
    return this.wrapCallInRetry(() =>
      this.client.query(Call(q.Function("get_user_info"), aliasUDF))
    ).catch(err => {
      /*       console.log("catch/querymanager/get_user_info/error-fetching ", err) */
      throw err
    })
  }

  QM_allow_cancel(subscriptionIDUDF) {
    return this.wrapCallInRetry(() =>
      this.client.query(Call(q.Function("allow_cancel"), subscriptionIDUDF))
    ).catch(err => {
      /*       console.log("catch/querymanager/allow_cancel/error-fetching ", err) */
      throw err
    })
  }

  QM_allow_subscribe() {
    return this.client
      .query(Call(q.Function("allow_subscribe")))
      .then(res => {
        /*         console.log("QM_allow_subscribe/res: ", res) */
        return res
      })
      .catch(err => {
        /*         console.log("QM_allow_subscribe/catch: ", err) */
        throw err
      })
  }

  /*   QM_save_my_info(myNewInfoUDF) {
    return this.client
      .query(Call(q.Function("save_my_info"), myNewInfoUDF))
      .then(res => {
        //         console.log("QM_save_my_info/res: ", res)
        return res
      })
      .catch(err => {
        //         console.log("QM_save_my_info/catch: ", err)
        throw err
      })
  } */

  QM_save_my_info(myNewInfoUDF) {
    return this.wrapCallInRetry(() =>
      this.client.query(Call(q.Function("save_my_info"), myNewInfoUDF))
    ).catch(err => {
      throw err
    })
  }

  /*   QM_buy_cosmetic(cosmeticNameUDF) {
    return this.client
      .query(Call(q.Function("buy_cosmetic"), cosmeticNameUDF))
      .then(res => {
        //         console.log("QM_buy_cosmetic/res: ", res)
        return res
      })
      .catch(err => {
        //         console.log("QM_buy_cosmetic/catch: ", err)
        throw err
      })
  } */

  QM_buy_cosmetic(cosmeticNameUDF) {
    return this.wrapCallInRetry(() =>
      this.client.query(Call(q.Function("buy_cosmetic"), cosmeticNameUDF))
    ).catch(err => {
      throw err
    })
  }

  /*   QM_get_my_cosmetics() {
    return this.client
      .query(Call(q.Function("get_my_cosmetics")))
      .then(res => {
        //         console.log("QM_get_my_cosmetics/res: ", res)
        return res
      })
      .catch(err => {
        //         console.log("QM_get_my_cosmetics/catch: ", err)
        throw err
      })
  } */

  QM_get_my_cosmetics() {
    return this.wrapCallInRetry(() =>
      this.client.query(Call(q.Function("get_my_cosmetics")))
    ).catch(err => {
      throw err
    })
  }

  async wrapCallInRetry(queryFun) {
    return queryFun()
      .then(res => {
        /*         console.log("wrapCallInRetry ~ res: ", res) */
        return res
      })
      .catch(err => {
        /*         console.log("wrapCallInRetry ~ err: ", err) */
        return this.checkAccessOrRefresh()
          .then(res => {
            this.user = res && res.user
            return queryFun()
          })
          .catch(err => {
            /*             console.log("QueryManager -> wrapCallInRetry -> err", err) */
            throw err
          })
      })
  }

  checkAccessOrRefresh() {
    return this.postData(
      urljoin(process.env.GATSBY_BASE_API, ".netlify/functions/refresh-await"),
      {}
    )
      .then(res => {
        /* console.log("QueryManager -> checkAccessOrRefresh -> then -> res", res) */
        this.client = new faunadb.Client({ secret: res.secret })
        this.user = res && res.user
        return res
      })
      .catch(err => {
        /* console.log(
          "QueryManager -> checkAccessOrRefresh -> catch -> error calling frontend",
          err
        ) */
        throw err
      })
  }

  discordPostDeck(webhookUrl, deckRefNum, name, color) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        content: `New ${color} deck! ${name} → https://mtg.builders/deck/${deckRefNum}`,
      }),
    }
    fetch(webhookUrl, requestOptions)
  }

  async postData(url, data = {}) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    /* console.log("🚀 ~ postData ~ response", response) */

    /*     console.log("postData/response", response) */
    if (!response.ok) {
      return response.json().then(err => {
        /* console.log("🚀 ~ returnresponse.json ~ err", err) */
        throw err
      })
    } else {
      return response.json()
    }
  }
}

const faunaQueries = new QueryManager()

export { faunaQueries, QueryManager }
