import React from "react"
import { QueryCache, ReactQueryCacheProvider } from "react-query"

import { SessionProvider } from "../context/session"
import { UIProvider } from "../context/ui"
import GlobalStyles from "./globalStyles"
import { OnMount } from "./appwrapper-onmount"

import { PayPalScriptProvider } from "@paypal/react-paypal-js"

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      /*       suspense: false,
      queryKeySerializerFn: defaultQueryKeySerializerFn,
      queryFn,
      enabled: true,
      retry: 3,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
      staleTime: 0,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchInterval: false,
      queryFnParamsFilter: identity,
      refetchOnMount: true,
      isDataEqual: deepEqual,
      onError: noop,
      onSuccess: noop,
      onSettled: noop,
      useErrorBoundary: false, // falls back to suspense */
      retry: false,
      cacheTime: 1000 * 60 * 60, // default is 1000 * 60 * 5 === 5 minuti
      refetchOnWindowFocus: false, // default is true
      refetchOnMount: true, // default is true
    },
    /*     mutations: {
      suspense: false,
      throwOnError: false,
      onMutate: noop,
      onError: noop,
      onSuccess: noop,
      onSettled: noop,
      useErrorBoundary: false, // falls back to suspense
    }, */
  },
})

function App({ children }) {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <PayPalScriptProvider
        options={{
          "client-id": process.env.GATSBY_PAYPAL_CLIENT,
          vault: true,
          currency: "USD",
          intent: "subscription",
          /* "buyer-country": "DE", // for testing only */
        }}
      >
        <SessionProvider>
          <UIProvider>
            <GlobalStyles />
            <OnMount>{children}</OnMount>
          </UIProvider>
        </SessionProvider>
      </PayPalScriptProvider>
    </ReactQueryCacheProvider>
  )
}

export default App
