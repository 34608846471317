import React from "react"

const SessionContext = React.createContext({})

export const sessionReducer = (state, action) => {
  switch (action.type) {
    case "login": {
      return { user: action.data }
    }
    case "register": {
      /*       console.log(action.data.user) */
      return { user: action.data.user }
    }
    case "logout": {
      return { user: null }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const SessionProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(sessionReducer, {
    user: null,
    /*     user: {
      alias: "affio",
      joinedAt: "2021-01-16T17:39:46.316763Z",
      twitch: "nuovotrich",
      youtube: "iltubo",
      patreon: "patrozzo",
      twitter: "iltubo",
      facebook: "patrozzo",
      back: "moldtokensg",
      shape: "moldtk",
      sfondo: "orlando",
      points: 5000,
      // when merged
      start_time: "nevermore",
      tier: "rare",
    }, */
  })

  return (
    <SessionContext.Provider value={{ state, dispatch }}>
      {children}
    </SessionContext.Provider>
  )
}

/* export const SessionProvider = SessionContext.Provider */
/* export const SessionConsumer = SessionContext.Consumer */
export default SessionContext
