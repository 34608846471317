import React from "react"

const UIContext = React.createContext({})

export const UIReducer = (state, action) => {
  switch (action.type) {
    case "SIMPLE_CHANGE": {
      return { ...state, [action.chiave]: action.valore }
    }
    case "ADD_CARD": {
      return {
        ...state,
        /*         [action[whichCards]]: [...action[whichCards], action.cardToAdd], */
        [action.whichCards]: [
          ...state[action.whichCards],
          {
            isFiltering: true, // proprieta' non esistente in partenza (da switchare)
            name: action.cardToAdd,
            hasBeenClickedBefore: true, // proprieta' non esistente in partenza
          },
        ],
      }
    }
    case "REMOVE_CARD": {
      return {
        ...state,
        [action.whichCards]: state[action.whichCards].filter(
          curCardObj => curCardObj.name !== action.cardToRemove
        ),
      }
    }
    case "SWITCH_CARD":
      return {
        ...state,
        [action.whichCards]: state[action.whichCards].map(cardObj => {
          if (cardObj.name === action.cardToSwitch) {
            cardObj.isFiltering = !cardObj.isFiltering
            return cardObj
          } else {
            return cardObj
          }
        }),
      }
    case "CHANGE_FORMAT":
      return {
        ...state,
        deckFormat: action.valore === "ALL" ? "ALL" : action.valore + state.bo,
      }
    case "SWITCH_BO":
      return {
        ...state,
        bo: action.valore,
        deckFormat:
          state.deckFormat === "ALL"
            ? "ALL"
            : state.deckFormat.slice(0, -3) + action.valore,
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const UIProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(UIReducer, {
    withinDays: 365,
    deckFormat: "ALL",
    deckColor: "ALL",
    cardsIncluded: [],
    cardsExcluded: [],
    orderByUDF: "time",
    bo: "BO3",
  })

  return (
    <UIContext.Provider value={{ state, dispatch }}>
      {children}
    </UIContext.Provider>
  )
}

/* export const UIProvider = UIContext.Provider */
/* export const SessionConsumer = UIContext.Consumer */
export default UIContext

export function handleUI_SIMPLE_CHANGE(dispatcher, chiave, valore) {
  dispatcher({
    type: "SIMPLE_CHANGE",
    chiave: chiave,
    valore: valore,
  })
}

export function handleUI_CHANGE_FORMAT(dispatcher, valore) {
  dispatcher({
    type: "CHANGE_FORMAT",
    valore: valore,
  })
}
export function handleUI_SWITCH_BO(dispatcher, valore) {
  dispatcher({
    type: "SWITCH_BO",
    valore: valore,
  })
}
