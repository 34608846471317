import React from "react"
import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
/*   @font-face {
    font-family: "Goudy Mediaeval";
    src: url("fonts/goudymediaeval-400-regular.woff2");
  } */

  :root {
// HOW TO DO RESPONSIVE
/*     --font-size-small: 16px;
    --font-size-medium: 22px;
    @media (min-width: 1024px) {
      --font-size-small: 21px;
      --font-size-medium: 24px;
    } */
    --overlap-card: 110px;
    --overlap-card-negative: -110px;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    background: radial-gradient(#555, #111);
  }

  *,
  *::before,
  *::after {
      margin: 0;
      padding: 0;
      box-sizing: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
    margin: 0;
   /*  font-size: 100%;
  line-height: 1.15; */ 
  }

  body {
/*     background: var(--color-background);
    color: var(--color-text); */
    font-family: 'Goudy Mediaeval', serif, sans-serif;
    font-size: 1.6em;
  }

  body::-webkit-scrollbar {
    width: 0px;
  }

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    text-decoration: none;
    color: inherit;
  }

  a:active,
  a:hover {
    outline-width: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }

`

export default GlobalStyles
