import React, { useEffect, useContext } from "react"
import { ToastProvider } from "react-toast-notifications"
/* import { ReactQueryDevtools } from "react-query-devtools/dist/react-query-devtools.production.min" */
import { ReactQueryDevtools } from "react-query-devtools"
import { useIsFetching } from "react-query"

import SessionContext from "../context/session"
import { faunaQueries } from "../fauna/query-manager"

export const OnMount = props => {
  const sessionContext = useContext(SessionContext)
  /*   console.log("OnMount/sessionContext", sessionContext) */

  const isFetching = useIsFetching()

  useEffect(() => {
    faunaQueries
      .checkAccessOrRefresh()
      .then(res => {
        /*         console.log("OnMount/checkaccessorrefresh/then/res", res) */
        if (res && !res.error) {
          sessionContext.dispatch({
            type: "login",
            data: faunaQueries.getUser(),
          })
        }
      })
      .catch(err => {
        /*         console.log("OnMount/checkaccessorrefresh/catch/err", err) */
        /* logoutBecauseUnauthorized(sessionContext, addToast, err.error) */
        // broken
        throw "Log-in to access all features"
      })
  }, [])

  return (
    <ToastProvider autoDismissTimeout={3000} autoDismiss={true}>
      {/* {isFetching && <h2>isFetching</h2>} */}
      {props.children}
      <ReactQueryDevtools initialIsOpen={false} />
    </ToastProvider>
  )
}
